<template>
	<div>
		<v-alert color="success" dismissible v-model="showSuccessAlert" dark icon="fas fa-check">
			Trova Visi completato
		</v-alert>
		<v-alert color="info" dark v-if="uploadQueue.length > 0">
			<div class="text-h4" v-if="loadingScaleOut">Server in preparazione</div>

			<span class="text-h4" v-else> {{ uploadQueue.length }} file in coda</span>
			<v-progress-linear indeterminate color="white" class="my-3"></v-progress-linear>
		</v-alert>
	</div>
</template>

<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";
import { QueueStatus } from "./queueStatusEnum.js";
import api from "@/services/api"
import { asyncPool, sleep } from "../AsyncPool.js";

export default {
	props: {
		uploadQueue: { type: Array, required: true },
		currentSubFolder: { type: String },
		EventId: { type: Number },
		processNewFiles: { type: Boolean, default: false },
		eventTypeId: { type: Number, default: 2 /* face */ }
	},
	data() {
		return {
			showSuccessAlert: false,
			paralellTaskInProgress: false,
			loadingScaleOut: false,
		};
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
		this.QueueStatus = QueueStatus;
		this.DelayFirstBatchMs = 500;
		this.ParallelTasksCount = 8;
	},
	computed: {
		QueuedFiles() {
			return this.uploadQueue.filter(
				(e) => e.uploadStatus === this.QueueStatus.QUEUED
			);
		},
		ProcessingFiles() {
			return this.uploadQueue.filter(
				(e) => e.uploadStatus === this.QueueStatus.UPLOADING
			);
		},
	},
	watch: {
		uploadQueue: {
			immediate: true,
			handler: async function (queue) {
				if (queue.length > 0) {
					this.$log.debug("watcher calling UploadFilesFromQueue");
					await this.UploadFilesFromQueue();
				}
			},
		},
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async UploadFilesFromQueue() {
			// if there is a async pool running do not start a new one
			if (this.paralellTaskInProgress) {
				this.$log.debug("paralellTaskInProgress");
				return;
			}

			// await this.setServerInstanceSize();

			while (this.QueuedFiles.length !== 0) {
				try {
					this.paralellTaskInProgress = true;

					await asyncPool(this.ParallelTasksCount, this.QueuedFiles, (file, i) =>
						this.UploadFile(file, i)
					);

					this.$log.debug("asyncPool done!");
				} finally {
					this.paralellTaskInProgress = false;
				}
			}
		},

		async DetectFaces(path) {
			try {
				const azureFunctionClient = new CrudClient("Face", api.azureFunctionApiUrl);

				const updatedItems = await azureFunctionClient.Post(
					this.EventId,
					{ ImageFilePath: path },
					"IdentifyFaces",
					true
				);

				return updatedItems;
			} catch (error) {
				this.snackError({ Text: "Errore in file:" + path });
				this.$captureError(error);
			}
		},

		async DetectCars(path) {
			try {
				const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);

				const updatedItems = await azureFunctionClient.Post(
					this.EventId,
					{ ImageFilePath: path },
					"AnalyseImage?useCachedIdentifiers=true",
					true
				);

				return updatedItems;
			} catch (error) {
				this.snackError({ Text: "Errore in file:" + path });
				this.$captureError(error);
			}
		},

		async UploadFile(file, index) {
			this.$log.debug("uploading: " + file.name);

			file.uploadStatus = this.QueueStatus.UPLOADING;

			let parameters = "isEditedVersion=false";
			if (this.currentSubFolder) {
				parameters += "&subFolder=" + encodeURIComponent(this.currentSubFolder);
			}

			let blobFile;
			try {
				if (index < this.ParallelTasksCount) {
					await sleep(index * this.DelayFirstBatchMs);
				}

				blobFile = await this.SgpEventsService.UpdateImage(
					this.EventId,
					file.name,
					file,
					"postimage?" + parameters
				);

				// insert to the Event files
				blobFile[0].Selected = false;
				this.$emit("fileUploaded", blobFile[0]);

				if (this.processNewFiles) {
					if (this.eventTypeId === 2) {
						blobFile = await this.DetectFaces(blobFile[0].Path);
						this.$log.debug(file.name + " detected");
						this.$emit("fileUpdated", [blobFile]);
					} else if (this.eventTypeId === 3) {
						const identifyResult = await this.DetectCars(blobFile[0].Path);
						blobFile = identifyResult.BlobFileDTO;
						this.$emit("fileUpdated", [blobFile]);
					}
				}
			} catch {
				this.snackError({ Text: "Impossibile caricare " + file.name });
			} finally {
				// remove uploaded item from queue
				const index = this.uploadQueue.findIndex((f) => f.name === file.name);
				this.uploadQueue.splice(index, 1);
			}
		},
	},
};
</script>
