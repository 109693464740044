<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="CloseDialog"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formAddEditEvent"
			:formContent="formAddEditEvent"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			:mode="mode"
			@submitForm="AddOrSave"
			:showCancelButton="false"
			:locali18n="$i18n"
			:initialValues="itemDetails"
		>
			<template slot="card-title">
				<div
					class="primary white--text px-5 py-3 d-flex align-center justify-space-between"
				>
					<span class="headline" style="word-break: normal">{{
						mode == 1
							? $t("addFtpConnection_Header")
							: $t("editFtpConnectionDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "AddEditFtpConnection" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			// CreateDate	smalldatetime	Unchecked
			// EventDate	smalldatetime	Checked
			// Location	nvarchar(250)	Checked
			formAddEditEvent: new CrudFormData("formAddEditEvent", [
				"Name",
				{
					type: "DatePicker",
					FieldName: "EventDate",
				},
				"Location",
			]),

			itemDetails: {},
		};
	},
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		EventType: { type: Number, default: 1 },
		EventId: { type: Number },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
		EventId: async function (val) {
			this.$log.debug("watch EventId");
			this.$log.debug(val);

			if (val) {
				this.itemDetails = await this.SgpEventsService.Get(val);
			}
		},
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
	},
	async mounted() {
		this.$log.debug("Add/Edit SgpEvents Dialog mounted");
		await this.LoadMetadata(this.SgpEventsService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSave(dto) {
			this.formAddEditEvent.FormLoading = true;

			try {
				if (this.mode === 1) {
					dto.EventType = this.EventType;
					await this.SgpEventsService.Post(null, dto);
					this.snackSuccess({ Text: this.$t("common.success.addItem") });
				} else {
					await this.SgpEventsService.Patch(this.EventId, dto, false);
					this.snackSuccess({ Text: this.$t("common.success.updateData") });
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formAddEditEvent.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formAddEditEvent) {
				this.$refs.formAddEditEvent.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},
	},
};
</script>
<i18n>
{
	"it": {
		"addFtpConnection_Header": "Nuovo event",
		"editFtpConnectionDialog_Header": "Modifica event",
		"formAddEditEvent":
		{
			"Name" : "Nome Evento",
			"EventDate": "Data evento",
			"Location": "Location"
		}
	}
}
</i18n>
