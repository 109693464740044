<template>
	<div>
		<span v-for="(folder, index) in Folders" :key="folder.totalPath">
			<span
				v-if="index !== Folders.length - 1"
				class="info--text pointer"
				@click="onLinkClick(folder)"
				>{{ folder.name | emptyField }}
			</span>
			<span v-else class="grey--text">
				{{ folder.name | emptyField }}
			</span>
			<v-icon class="secondary--text text--lighten-2 mx-3"
				>fas fa-caret-right</v-icon
			>
		</span>

		<v-icon @click="showNewFolderDialog = true">fas fa-folder-plus</v-icon>
		<v-dialog v-model="showNewFolderDialog" max-width="400px">
			<v-card>
				<v-card-title>Crea nuova cartella</v-card-title>
				<v-card-text>
					<v-text-field v-model="newFolderName"></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="CreateFolder">Crea</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			showNewFolderDialog: false,
			newFolderName: null,
		};
	},
	props: {
		Path: {
			type: String,
			default: null,
		},
	},
	computed: {
		Folders() {
			const retArray = [];
			retArray.push({ name: "Inizio", totalPath: null });

			if (!this.Path) return retArray;

			const folders = this.Path.split("/");

			// this.$log.debug("path:" + this.Path);
			// this.$log.debug("split res:");
			// this.$log.debug(folders);

			let totalPath = "";
			for (let i = 0; i < folders.length - 1; i++) {
				totalPath += folders[i] + "/";

				retArray.push({ name: folders[i], totalPath });
			}
			// this.$log.debug("Ret Array");
			// this.$log.debug(retArray);

			return retArray;
		},
	},

	methods: {
		onLinkClick(folder) {
			// this.$log.debug("Emit:" + folder.totalPath);
			this.$emit("navigate-to", folder.totalPath);
		},
		CreateFolder() {
			if (!this.newFolderName) return;

			const lastFolder = this.Folders[this.Folders.length - 1];
			const emitPath = (lastFolder.totalPath || "") + this.newFolderName + "/";
			// this.$log.debug("Emit: " + emitPath);

			this.$emit("navigate-to", emitPath);

			this.newFolderName = null;
			this.showNewFolderDialog = false;
		},
	},
};
</script>
