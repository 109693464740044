export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function asyncPool(poolLimit, iterable, iteratorFn) {
	let i = 0;
	const ret = [];
	const executing = new Set();
	const enqueue = function () {
		if (i === iterable.length) {
			return Promise.resolve();
		}
		const item = iterable[i];
		const p = Promise.resolve().then(() => iteratorFn(item, i));
		i++;
		ret.push(p);
		executing.add(p);
		const clean = () => executing.delete(p);
		p.then(clean).catch(clean);
		let r = Promise.resolve();
		if (executing.size >= poolLimit) {
			r = Promise.race(executing);
		}
		return r.then(() => enqueue());
	};
	return enqueue().then(() => Promise.all(ret));
}

export async function Retry(action, retryInterval = 2000, maxAttemptCount = 3) {
	const exceptions = [];
	for (let attempted = 0; attempted < maxAttemptCount; attempted++) {
		try {
			console.log("Attempt No: " + attempted);
			if (attempted > 0) {
				await sleep(retryInterval);
				// console.log("Sleep completed");
			}
			return await action();
		} catch (e) {
			exceptions.push(e);
		}
	}

	console.log([exceptions]);

	throw new Error("Retry failed");
}

export function makeid(length) {
	let result = "";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
