<template>
	<div @click="onClick">
		<v-hover v-slot="{ hover }">
			<div
				class="selectableItem rounded"
				:class="{ 'percentagePadding blue lighten-4': value }"
			>
				<div class="selectArea">
					<v-icon v-if="!value" :color="hover ? 'white' : 'grey'">
						{{ hover ? selectedIcon : unselectedIcon }}
					</v-icon>
					<v-icon v-else color="blue">
						{{ selectedIcon }}
					</v-icon>
				</div>

				<div class="zoomArea">
					<v-icon :color="hover ? 'white' : 'grey'" @click.stop="zoomClick($event)"
						>fas fa-search</v-icon
					>
				</div>

				<slot> </slot>
			</div>
		</v-hover>
	</div>
</template>
<script>
export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		selectedIcon: {
			type: String,
			default: "fas fa-check-circle",
		},
		unselectedIcon: {
			type: String,
			default: "far fa-circle",
		},
	},
	methods: {
		onClick(event) {
			if (this.value) {
				this.$emit("unselectClick", event);
				this.$emit("input", false);
			} else {
				this.$emit("selectClick", event);
				this.$emit("input", true);
			}
		},

		zoomClick(event) {
			this.$emit("zoomClick", event);
		},
	},
};
</script>
<style lang="scss" scoped>
.percentagePadding {
	transition: 0.3s;
	padding: 10px;
}

.selectableItem {
	position: relative;
	transition: 0.3s;
}

.selectArea {
	position: absolute;
	z-index: 2;
	top: 10px;
	left: 10px;
}

.zoomArea {
	position: absolute;
	z-index: 2;
	bottom: 10px;
	right: 10px;
}
</style>
